import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBlogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/blogs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBlogCategoryList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/blog/category/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBlog(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/blog/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBlog(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/blog/${id}/show`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBlog(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/blog/${id}/update`,body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   deleteBlog(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/blog/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
