export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.value !== undefined ? inputEl.value.files[0] : inputEl[0]
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,
  }
}

export const useInputImageMultipleRenderer = (inputEl, callback) => {
  const inputImageMultipleRenderer = () => {
    console.log(inputEl, 'input rel')
    const images = []
    const { files } = inputEl.value

    files.forEach(file => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          images.push(reader.result)
        },
        false,
      )
      if (file) {
        reader.readAsDataURL(file)
      }
    })
    callback(images)
  }
  return {
    inputImageMultipleRenderer,
  }
}

export const _ = null
